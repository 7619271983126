.book h2 {
  display: block;
  text-align: center;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.book h1:after,
.book h2:after {
  content: "";
  display: block;
  position: relative;
  top: 0.33em;
  border-bottom: 1px solid hsla(0, 0%, 50%, 0.33);
}

.book p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
